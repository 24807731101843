'use strict';

import angular from 'angular';
import * as _ from "lodash";
import {SimpleModal} from "../../app/simpleModal/simpleModal"
import {Decimal} from "decimal.js";

export class PersonalInformationInputsComponent {

  $q;

  /** @type {!SimpleModal} */
  simpleModal;

  /** @type {!DataLoader} */
  dataLoader;

  /** @type {!cartMemoryService} */
  cartMemory;

  /** Data that gets sent to CyberSource. */
  csData;

  /** @type {(StateCode|string)} */
  selectedStateCode;

  /** @type {CountryCode} */
  selectedCountryCode;

  /** @type {CountryCode} */
  selectedCitizenShipCountryCode

  /**
   * Only want selectedStateCode initted once by pcom/atlas data.
   * @type {boolean}
   */
  selectedStateCodeInitted = false;

  /**
   * From above.
   * @type {function}
   */
  isStripeCart;

  /*@ngInject*/
  constructor(
    $q,
    $scope,
    $http,
    userData,
    currencySymbol,
    dataLoader,
    cartMemory,
    $location,
    $stateParams,
    $interval,
    Util,
    simpleModal
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.$http = $http;
    this.userDataService = userData;

    this.currencySymbolService = currencySymbol;
    this.dataLoader = dataLoader;
    this.cartMemory = cartMemory;

    this.$location = $location;

    this.$stateParams = $stateParams;

    this.$interval = $interval;

    this.simpleModal = simpleModal;
    this.UtilService = Util;
  }

  $doCheck() {
    // SD: to support GIVING-410
    if (this.formName.country) {
      if (
        this.isStripeCart()
        && this.selectedCountryCode
        && _.get(this, 'model.address.countryCode', '') === 'GB'
        && this.currencySymbolService.get().code !== 'USD'
        && this.currencySymbolService.get().code !== 'GBP') {
        this.formName.country.$setValidity('countryAndCurrency', false);
      } else {
        this.formName.country.$setValidity('countryAndCurrency', true);
      }
    }
  }

  showUkAlert = () => {
    this.model.giftAid = !this.model.giftAid;
    if (!this.model.giftAid) {
      return;
    }
    var options = {
      header: 'Gift Aid',
      message: '<p>I acknowledge that I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax '
        + 'in the current tax year than the amount of Gift Aid claimed on all my donations, it is my responsibility to '
        + 'pay any difference. I understand the charity will reclaim 25p of tax on every £1 that I give.'
        + '<p><p>Learn more <a href="http://igifts.upenn.edu/international-network2/united-kingdom" target="_blank">here</a>',
      okText: 'I Agree',
      callback: function (err, data) {
        console.log('logout modal response = ' + data)
      }
    };
    this.simpleModal.showAlert(options);
  };

  dropdownSelected = (item, type) => {
    if (type == 'country') {
      this.model.address.country = item.name;
      this.model.address.countryCode = item.code;
      this.model.giftAid = null;
      this.model.optOutPaper = null;
      this.selectedCountryCode = this.dataLoader.getCountryForCode(item.code);

      // SD: to support GIVING-410 and leave non-stripe logic untouched
      if (!this.isStripeCart()) {
        this.model.GBP = null;
        this.currencySymbolService.reset();
      }
    } else if (type == 'citizenship') {
      this.model.citizenShipCountry = item.name;
      this.model.citizenShipCountryCode = item.code;
      this.selectedCitizenShipCountryCode = this.dataLoader.getCountryForCode(item.code);
    } else {
      this.model[type] = item.name;
    }

  };

  listStates = (searchString) => {
    return this.matchStates(searchString, this.states) || [];
  };

  matchStates = (searchString, states) => {
    let results = [];
    let regex = new RegExp(searchString, 'i');
    for (let state of states) {
      if (searchString.toUpperCase() == state.state_code.toUpperCase()) {
        results.push(state);
      }
    }
    for (let state of states) {
      if (regex.test(state.display_state_name)) {
        if (results.indexOf(state) == -1) {
          results.push(state);
        }
      }
    }
    return results;
  };

  showPaperOption() {
    let controls = this.dataLoader.getControlParams('SHOW_OPT_OUT_PAPER_RECEIPT');
    return controls.length > 0 && controls[0].value === 'true';
  }

  paperOptionLabel() {
    let controls = this.dataLoader.getControlParams('SHOW_OPT_OUT_PAPER_RECEIPT');
    return controls.length > 0 ? controls[0].description : '';
  }

  /**
   * @param {string} searchString
   * @return {CountryCode[]}
   */
  matchCountries(searchString, type) {
    let results = [];
    let regex = new RegExp(searchString, 'i');
    if (type == 'country') {
      for (/* @type {CountryCode} */ let country of this.countries) {
        if (regex.test(country.country_name)) {
          results.push(country);
        }
      }
    } else {
      for (/* @type {CountryCode} */ let country of this.citizenShipCountries) {
        if (regex.test(country.country_name)) {
          results.push(country);
        }
      }
    }
    return results;
  }

  onStateSelect = (state) => {

    for (var i = 0; i < this.countries.length; i++) {
      if (this.countries[i].code === state.country_code) {
        this.dropdownSelected({"name": this.countries[i].name, "code": state.country_code}, "country");
        break;
      }
    }
  };

  changeCurrency = (symbol) => {
    if (this.currencySymbolService.get().symbol === symbol) {
      this.currencySymbolService.reset();
    } else {
      this.currencySymbolService.set(symbol);
    }
  };

  updateSelectedStateCode() {
    let country = this.dataLoader.getCountryForCode(this.model.address.countryCode);
    this.dropdownSelected(country, 'country');
    if (this.model.address.stateCode) {
      this.selectedStateCode = this.dataLoader.getStateByCodeAndCountry(this.model.address.stateCode, country);
    }
  }

  getCurrentCitizenShip() {
    return this.shouldShowCitizenShip ? 'U' : null;
  }

  getCitizenShipTypeCode() {
    return this.shouldShowCitizenShip ? 'US' : null;
  }

  $onInit() {

    // SD: this used to be in constructor - move it to $onInit for easier testing (so
    // we can call the constructor with undefined dataLoader
    this.model = {
      "address": {
        "country": this.dataLoader.countryCodes[0].country_name,
        "countryCode": this.dataLoader.countryCodes[0].country_code,
      },
      "citizenshipType": null,
      "citizenShipCountry": null,
      "citizenShipCountryCode": null

    };

    this.needsValidation = false;
    if (this.userDataService.get() !== false) {
      this.model = this.userDataService.get();
      this.needsValidation = true;
      this.showInputs = true;
    } else {
      this.showInputs = false;
    }

    const params = this.dataLoader.getControlParams('CITIZENSHIP_THRESHOLD');
    const code = this.currencySymbolService.get().code;
    let amount = this.convertAmountForCurrency(
      this.cartMemory.getTotals().donorAdvisedFund || this.cartMemory.getTotals().cyberSourceAmount,
      code,
      'USD',
      2
    );
    if (params && params.length > 0) {

      if (amount == 0 && this.$stateParams.amount) {
        amount = this.$stateParams.amount
      }
      const threshold = this.dataLoader.getControlParams('CITIZENSHIP_THRESHOLD')[0].value;
      this.shouldShowCitizenShip = parseFloat(amount) >= parseInt(threshold);
    } else {
      this.shouldShowCitizenShip = false;
    }


    if (!this.shouldShowCitizenShip) {
      this.model.citizenshipType = this.getCurrentCitizenShip();
      this.model.citizenShipCountry = this.dataLoader.countryCodes[0].country_name;
      this.model.citizenShipCountryCode = this.getCitizenShipTypeCode();
    }


    this.states = this.dataLoader.stateCodes
      .map(item => {
        item.name = item.state_name;
        item.code = item.state_code;
        return item;
      });

    // GIVING-247
    let usStateCodes = [];

    for (let state of this.states) {
      state.display_state_name = state.state_name;
      if (state.country_code == 'US') {
        state.display_state_name = state.state_code + ' - ' + state.display_state_name;
        usStateCodes.push(state);
      }
    }

    this.states = this.states.filter(item => {
      // let's get rid of all us states
      if (usStateCodes.indexOf(item) == -1) {
        return true;
      }
      return false;
      // return !usStateCodes.includes(item);
    });

    this.states = usStateCodes.concat(this.states);

    // END GIVING-247

    this.countries = this.dataLoader.countryCodes
      .map(item => {
        item.name = item.country_name;
        item.code = item.country_code;
        return item;
      });

    this.citizenShipCountries = [...this.dataLoader.countryCodes
      .map(item => {
        item.name = item.country_name;
        item.code = item.country_code;
        return item;
      })];

    // remove US from country list
    this.citizenShipCountries = this.citizenShipCountries.filter(item => item.code !== "US");


    this.$scope.$watch(angular.bind(this, function () {
      if (angular.isDefined(this.model.first_name) && angular.isDefined(this.model.last_name)) {
        this.userDataService.set(this.model);
        // Idea here is if the user info gets updated, we need to set the
        // selected state code, but don't overwrite it if it's already selected
        if (!this.selectedStateCodeInitted) {
          this.updateSelectedStateCode();
          this.selectedStateCodeInitted = true;
        }
      }
    }));


    let cyberSourceConfig = this.dataLoader.cyberSourceConfig;

    this.csData.signed_field_names =
      "access_key,profile_id,transaction_uuid,signed_field_names," +
      "unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency," +
      "ignore_avs,override_custom_receipt_page,merchant_defined_data4";
    this.csData.unsigned_field_names =
      "bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_line2," +
      "bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code";

    this.csData.access_key = cyberSourceConfig.secureAcceptanceKey;
    this.csData.locale = "en-US";
    this.csData.ignore_avs = "true";


    this.csData.recurring_start_date = '';
    this.csData.recurring_frequency = '';
    this.csData.recurring_number_of_installments = '';
    this.csData.recurring_automatic_renew = '';


    this.csData.override_custom_receipt_page =
      this.$location.protocol()
      + "://"
      + this.$location.host()
      + ':'
      + this.$location.port()
      //"/api/cyberSourceTest/";
      + "/api/cyberSource/response";

    if (this.$stateParams.fastStart === 'simpleForm') {
      this.csData.override_custom_receipt_page += '?fastStart=simpleForm';
    }


    this.$scope.$watch('[$ctrl.selectedStateCode]', () => {

      if (this.selectedStateCode && this.selectedStateCode.state_code) {
        this.model.address.state = this.selectedStateCode.state_name;
        this.model.address.stateCode = this.selectedStateCode.state_code;
      } else {
        // could be free text if they didn't select from the typeahead
        // dropdown


        this.model.address.state = null;
        this.model.address.stateCode = null;

        if (this.selectedStateCode) {


          let stateName = this.selectedStateCode;
          let stateCode = this.getStateCodeByName(stateName);

          if (!stateCode) {
            stateCode = this.getStateCodeByDisplayStateName(stateName);
          }

          if (!stateCode) {
            let countryCode = this.dataLoader.getCountryForCode('US');
            stateCode = this.dataLoader.getStateByCodeAndCountry(stateName.toUpperCase(), countryCode);
          }

          if (stateCode) {
            this.model.address.state = stateCode.state_name;
            this.model.address.stateCode = stateCode.state_code;

            // GIVING-307, update the country too if we have a state
            this.onStateSelect(stateCode);
          } else if (stateName) {
            this.model.address.state = stateName;
            this.model.address.stateCode = 'XX';
          }
        }
      }

      this.csData.bill_to_address_state = this.model.address.stateCode;

    }, true);


    this.$scope.$watch('[$ctrl.model.address.countryCode, $ctrl.model.GBP]', () => {
        this.csData.bill_to_address_country = this.model.address.countryCode;
        if (this.model.address.countryCode == 'GB') {
          if (this.model.GBP) {
            this.csData.currency = "GBP";
          } else {
            this.csData.currency = "USD";
          }
          this.csData.profile_id = cyberSourceConfig.secureAcceptanceProfileUk;
          this.csData.access_key = cyberSourceConfig.secureAcceptanceKeyUk;
        } else {
          this.csData.currency = "USD";
          this.csData.profile_id = cyberSourceConfig.secureAcceptanceProfile;
          this.csData.access_key = cyberSourceConfig.secureAcceptanceKey;
        }
      },
      true);
    this.$scope.$watch('[$ctrl.model.citizenShipCountryCode]', () => {
        this.csData.country_of_citizenship = this.model.citizenShipCountryCode;
      },
      true);

    this.$scope.$watch('[$ctrl.model.citizenshipType]', () => {
        if (this.model.citizenshipType == 'U') {
          this.model.citizenShipCountry = this.dataLoader.countryCodes[0].country_name;
          this.model.citizenShipCountryCode = 'US';
          this.csData.country_of_citizenship = this.model.citizenShipCountryCode;
        } else {
          this.model.citizenShipCountryCode = null;
          this.model.citizenShipCountry = null;
        }
      },
      true);

    this.prefixNames = this.dataLoader.getControlParams('PREFIX_NAME');
    this.prefixNames = this.prefixNames.map((item) => {
      let newItem = {name: item.description};
      return newItem;
    });
    this.prefixNames = [{name: null}].concat(this.prefixNames);

    this.suffixNames = this.dataLoader.getControlParams('SUFFIX_NAME');
    this.suffixNames = this.suffixNames.map((item) => {
      let newItem = {name: item.description};
      return newItem;
    });
    this.suffixNames = [{name: null}].concat(this.suffixNames);


    this.$interval(() => {
      //this.needsValidation = !this.touchAllErrorFields();
      if (this.needsValidation) {
        this.touchAllErrorFields();
      }
    }, 500, 1);

  }

  /**
   * This method will trigger visual display of errors
   * @returns {boolean} true if the formName exists (because it has been checked), false if not
   */
  touchAllErrorFields() {
    let result = false;
    if (this.formName) {
      result = true;

      if (this.formName.$invalid) {
        angular.forEach(this.formName.$error, function (field) {
          angular.forEach(field, function (errorField) {
            errorField.$setTouched();
          })
        });
      }
    }
    return result;


  }


  getStateCodeByName(stateName) {
    for (let /** @type {StateCode} */ stateCode of this.states) {
      if (stateCode.state_name.toUpperCase() === stateName.toUpperCase()) {
        return stateCode;
      }
    }
    return null;
  }


  getStateCodeByDisplayStateName(displayStateName) {
    for (let /** @type {StateCode} */ stateCode of this.states) {
      if (stateCode.display_state_name && stateCode.display_state_name.toUpperCase() === displayStateName.toUpperCase()) {
        return stateCode;
      }
    }
    return null;
  }

  convertAmountForCurrency(amount, fromCurrencyCode, toCurrencyCode, decimalPlaces) {

    if (amount == null || fromCurrencyCode == null || fromCurrencyCode === toCurrencyCode) {
      return amount;
    }

    let toDollarsExchangeRate = _.get(this.dataLoader.getExchangeRate(fromCurrencyCode), 'currency_amount', null);
    let fromDollarsExchangeRate = _.get(this.dataLoader.getExchangeRate(toCurrencyCode), 'currency_amount', null);
    if (toDollarsExchangeRate == null || fromDollarsExchangeRate == null) {
      return amount;
    }
    let toDollarsExchangeRateDecimal = new Decimal(toDollarsExchangeRate);
    let fromDollarsExchangeRateDecimal = new Decimal(fromDollarsExchangeRate);
    let amountDecimal = new Decimal(amount);

    // first convert to dollars
    amountDecimal = amountDecimal.dividedBy(toDollarsExchangeRateDecimal);

    // now go to the target currency
    amountDecimal = amountDecimal.times(fromDollarsExchangeRateDecimal).toDecimalPlaces(decimalPlaces);
    let amountToCurrency = amountDecimal.toNumber();
    return amountToCurrency;
  }

}

export default angular.module('givingApp.personalInformationInputs', ['ui.bootstrap'])
  .directive('updateHidden', function () {
    return function (scope, el, attr) {
      var model = attr.ngModel;
      scope.$watch(model, function (val) {
        el.val(val);
      });
    };
  })
  .component('personalInformationInputs', {
    template: require('./personal-information.html'),
    controller: PersonalInformationInputsComponent,
    bindings: {
      formName: '<',
      model: '=',
      bank: '<',
      csData: '=',
      shouldShowCitizenShip: '=',
      shouldShowCitizenShipError: '=',
      isStripeCart: '&',
    }
  }).name;
