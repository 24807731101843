import Header from "../../givingService/Header";
import * as _ from "lodash";
import Detail from "../../givingService/Detail";

import {cartMemoryService} from "../cartMemory/cartMemory.service";
import {Decimal} from "decimal.js";

const angular = require('angular');

export class CheckoutService {

  $http;
  $sessionStorage;
  $q;
  $location;

  /** @type {!DataLoader} */
  dataLoader;
  /** @type {!cartMemoryService} */
  cartMemory;

  /** @type {currencySymbolService} */
  currencySymbolSvc;

  /** @type {GivingService} */
  givingService;

  utilService;

  /*@ngInject*/
  constructor($http,
              dataLoader,
              cartMemory,
              $sessionStorage,
              $q,
              $timeout,
              $location,
              currencySymbol,
              givingService,
              Util) {
    this.$http = $http;
    this.dataLoader = dataLoader;
    this.cartMemory = cartMemory;
    this.$sessionStorage = $sessionStorage;
    this.$q = $q;
    this.$timeout = $timeout;
    this.$location = $location;
    this.currencySymbolSvc = currencySymbol;
    this.givingService = givingService;
    this.utilService = Util;
  }

  /**
   * @param userInformation
   * @param csData
   * @param {boolean} usingStripeClassic means using non-digital wallet Stripe
   * @param {string} recaptchaToken
   * @param {?StripePaymentIntent} stripePaymentIntent
   * @param {?ChariotSuccessMetadata} chariotSuccessMetadata
   */
  storeGiftCart(
    userInformation,
    csData,
    usingStripeClassic,
    recaptchaToken,
    stripePaymentIntent,
    chariotSuccessMetadata,
  ) {
    let status = 'I';
    let emplid = null;
    // let's start by getting emplid
    return this.$http.get('/api/giving_donor_pers_info/justemplid/')
      .then(response => {
        emplid = response.data;
      })
      .catch(() => {
        emplid = '1579195';
      })
      .then(() => {

        // Nothing in the cart , forget about it.
        if (this.cartMemory.retrieve().length === 0) {
          return;
        }

        // credit card transaction results return from Cybersource
        let header = new Header();

        header.last_name = userInformation.last_name;
        header.first_name = userInformation.first_name;

        // it says initial but it's 60 chars long
        header.middle_initial = userInformation.middle_name;
        header.title = userInformation.title;
        header.name_suffix = userInformation.suffix;

        if (userInformation.address.addressType === 'home') {
          header.address_type = 'HOME';
        } else if (userInformation.address.addressType === 'business') {
          header.address_type = 'BUS';
        }

        header.street1 = userInformation.address.street_1;
        header.street2 = userInformation.address.street_2;
        header.city = userInformation.address.city;
        header.state_province = userInformation.address.stateCode;
        header.zip_postal_code = userInformation.address.zip;
        header.country = userInformation.address.countryCode;
        header.country_of_citizenship = userInformation.citizenShipCountryCode;

        header.phone = userInformation.phone;

        header.email = userInformation.email;

        header.donation_source = 'GIVING';

        if (userInformation.optOutPaper) {
          header.receipt_sw = 'F';
        } else {
          header.receipt_sw = 'T';
        }

        // only look at first one in cart for matching gift as per GIVING-61
        let firstCartItem = this.cartMemory.retrieve()[0];

        let employerSelected = _.get(firstCartItem, 'options.employer.selected');
        if (employerSelected) {
          header.gift_match_source = _.get(firstCartItem, 'employer.aditionalInformation.name');
        }

        header.brokerage_bank_name = _.get(userInformation, 'bankDetails.brokerage');
        header.broker_name = _.get(userInformation, 'bankDetails.name');
        header.broker_phone = _.get(userInformation, 'bankDetails.phone');
        header.broker_email = _.get(userInformation, 'bankDetails.email');


        if (userInformation.address.countryCode === 'GB') {
          header.uk_citizen = 'T';
        } else {
          header.uk_citizen = 'F';
        }

        if (userInformation.giftAid) {
          header.uk_gift_aid = 'T';
        } else {
          header.uk_gift_aid = 'F';
        }

        header.total_amount = this.cartMemory.getTotals().totalAmount;

        if (header.total_amount > 0) {
          header.currency_ind = 'USD';
          if (this.utilService.isStripeCart(this.cartMemory.cartContents)) {
            // use GIVING-410's new stripe logic - even if stripe is not used as in UK gifts (GIVING-443)
            header.currency_ind = this.currencySymbolSvc.get().code;
          } else {
            // use existing non-stripe logic
            if (userInformation.GBP) {
              header.currency_ind = 'GBP';
            }
          }
        } else {
          header.currency_ind = null;
        }
        header.alumni_degree_year = userInformation.alumni_degree_year || null;

        header.browser_timezone_offset_minutes = new Date().getTimezoneOffset();

        // if it's a digital wallet or DAF, this will be set on server-side
        if (usingStripeClassic) {
          header.payment_source = 'STRIPE';
        } else if (stripePaymentIntent == null && chariotSuccessMetadata === null) {
          header.payment_source = 'CYBERSOURCE';
        } else {
          header.payment_source = null;
        }

        if (stripePaymentIntent) {
          // only needed here for payment intent donations
          header.anonymous = this.getAnonymous(this.cartMemory);
        }
        return this.postHeader(
          header,
          recaptchaToken,
          stripePaymentIntent,
          csData,
          userInformation,
          chariotSuccessMetadata,
        );
      })
      .then(/** @type {!Header} */(header) => {
        let cartItems = this.cartMemory.retrieve();
        let headerAndDetailRequests = [header];
        for (let cartItem of cartItems) {
          let detail = this.writeNonpledgeDetail(cartItem, header, status, emplid);
          let pledgeDetail = null;
          switch (cartItem.type) {
            case -1:
            case 0:
            case 1:
            case 2:
              // recurring
              detail.gift_method_type = 'R';
              break;
            case 3:
              // single
              detail.gift_method_type = 'C';
              break;
            case 4:
              // recurring pledge
              pledgeDetail = this.writePledgeDetail(cartItem, header, status, emplid);
              detail.gift_method_type = 'R';
              break;
            case 5:
              // securities
              detail.gift_method_type = 'S';
              detail.share_number = cartItem.otherGift.security.numberOfShares;
              detail.security_name = cartItem.otherGift.security.name;
              detail.ticker_symbol = cartItem.otherGift.security.symbol;
              break;
            case 6:
              //  pledge with initial payemnt
              pledgeDetail = this.writePledgeDetail(cartItem, header, status, emplid);
              detail.gift_method_type = 'PP';
              break;
            case 7:
              // DAF
              detail.gift_method_type = 'D';
              break;
          }
          switch (cartItem.type) {
            case -1:
            case 0:
            case 1:
            case 2:
            case 4:
              // some kind of recurring payment
              detail.recurr_frequency
                = this.cartMemory.freqNumberToCharCode(cartItem.frequency);
              break;
          }

          if (detail.recurr_frequency) {
            detail.recurr_start_date = this.formatDate(new Date(cartItem.startDate));
          }
          detail.amount = this.cartMemory.getCyberSourceAmount(cartItem);
          if (pledgeDetail) {
            headerAndDetailRequests.push(this.postDetail(pledgeDetail, false));
          }
          headerAndDetailRequests.push(this.postDetail(detail, false));
        }
        return this.$q.all(headerAndDetailRequests);
      });
  }


  writePledgeDetail(cartItem, header, status, emplid) {
    let detail = this.writeNonpledgeDetail(cartItem, header, status, emplid);
    detail.gift_method_type = 'P';
    detail.pledge_frequency = this.cartMemory.freqNumberToCharCode(cartItem.frequency);
    detail.pledge_start_date = this.formatDate(new Date(cartItem.startDate));
    detail.pledge_total_year = this.cartMemory.getPledgeYears(cartItem);
    detail.amount = cartItem.otherGift.pledge.totalValue;
    return detail;

  }

  /**
   *
   * @param cartItem
   * @param header
   * @param {string} status
   * @param {string} emplid
   * @return {Detail}
   */
  writeNonpledgeDetail(cartItem, header, status, emplid) {
    let detail = new Detail();
    detail.gift_id = header.gift_id;
    detail.emplid = emplid;
    detail.school_code = cartItem.program_id;
    detail.group_code =
      this.findFirstSchoolBySchoolCode(detail.school_code, this.dataLoader.donationSchools).group_code;

    /** @type {DonationFund} */
    let fund = this.dataLoader.funds.find(fund => {
      return fund.hibernate_id === cartItem.id;
    });
    detail.fund_code = fund.fund_code;

    detail.anonymous_gift = _.get(cartItem, 'options.anonymous.selected') ? 'T' : 'F';

    detail.gift_method_type_joint = _.get(cartItem, 'options.joint.selected') ? 'T' : 'F';
    detail.gift_method_type_joint_name = _.get(cartItem, 'joint.aditionalInformation.name');

    if (_.get(cartItem, 'options.honor.selected')) {
      detail.in_honor_or_memory_of = 'H';
      let title = _.get(cartItem, 'honor.aditionalInformation.title', '');
      if (title) {
        title += ' ';
      }
      detail.in_honor_or_memory_of_name =
        title
        + _.get(cartItem, 'honor.aditionalInformation.firstname', '') + ' '
        + _.get(cartItem, 'honor.aditionalInformation.lastname', '');
      // this one's not currently in the form leave it in case it was a mistake
      detail.in_honor_or_memory_of_email =
        _.get(cartItem, 'honor.aditionalInformation.email');
      detail.in_honor_or_memory_of_address =
        _.get(cartItem, 'honor.aditionalInformation.address.street');
      detail.in_honor_or_memory_of_address2 =
        _.get(cartItem, 'honor.aditionalInformation.address.street2');
      detail.in_honor_or_memory_of_city = _.get(cartItem, 'honor.aditionalInformation.address.city');
      detail.in_honor_or_memory_of_state = _.get(cartItem, 'honor.aditionalInformation.address.state');
      detail.in_honor_or_memory_of_zip = _.get(cartItem, 'honor.aditionalInformation.address.zip');
      detail.in_honor_or_memory_of_country = _.get(cartItem, 'honor.aditionalInformation.address.country');

      detail.in_honor_or_memory_of_comments = _.get(cartItem, 'honor.aditionalInformation.comments');
    }
    detail.gift_special_reason = _.get(cartItem, 'options.special.selected') ? 'T' : 'F';
    detail.gift_special_reason_desc = _.get(cartItem, 'special.aditionalInformation.comments');

    if (this.cartMemory.isPlannedGift(cartItem)) {
      detail.planned_giving_flag = 'T';
      detail.planned_giving_more_info = _.get(cartItem, 'options.planned.moreInformation', false) ? 'T' : 'F';
      detail.planned_giving_penn_in_will = _.get(cartItem, 'options.planned.will', false) ? 'T' : 'F';
    } else {
      detail.planned_giving_flag = 'F';
      detail.planned_giving_more_info = 'F';
      detail.planned_giving_penn_in_will = 'F';
    }

    if (_.get(cartItem, 'options.memory.selected')) {
      detail.in_honor_or_memory_of = 'M';

      let title = _.get(cartItem, 'memory.aditionalInformation.title', '');
      if (title) {
        title += ' ';
      }
      detail.in_honor_or_memory_of_name =
        title
        + _.get(cartItem, 'memory.aditionalInformation.firstname', '') + ' '
        + _.get(cartItem, 'memory.aditionalInformation.lastname', '');

      let ackTitle = _.get(cartItem, 'memory.aditionalInformation.ackTitle', '');
      if (ackTitle) {
        ackTitle += ' ';
      }
      detail.in_honor_or_memory_ack_name =
        ackTitle
        + _.get(cartItem, 'memory.aditionalInformation.ackFirstname', '') + ' '
        + _.get(cartItem, 'memory.aditionalInformation.ackLastname', '');

      detail.in_honor_or_memory_ack_name = detail.in_honor_or_memory_ack_name.trim();

      if (!detail.in_honor_or_memory_ack_name) {
        // Just to be consistent with other fields
        detail.in_honor_or_memory_ack_name = null;
      }

      detail.in_honor_or_memory_of_email =
        _.get(cartItem, 'memory.aditionalInformation.email');

      detail.in_honor_or_memory_of_address =
        _.get(cartItem, 'memory.aditionalInformation.address.street');
      detail.in_honor_or_memory_of_address2 =
        _.get(cartItem, 'memory.aditionalInformation.address.street2');
      detail.in_honor_or_memory_of_city = _.get(cartItem, 'memory.aditionalInformation.address.city');
      detail.in_honor_or_memory_of_state = _.get(cartItem, 'memory.aditionalInformation.address.state');
      detail.in_honor_or_memory_of_zip = _.get(cartItem, 'memory.aditionalInformation.address.zip');
      detail.in_honor_or_memory_of_country = _.get(cartItem, 'memory.aditionalInformation.address.country');

      // wonder if it's a bug that this is under honor instead of memory
      detail.in_honor_or_memory_of_comments = _.get(cartItem, 'honor.aditionalInformation.comments');
    }

    detail.status = status;
    detail.appeal_code = cartItem.appeal;

    return detail;
  }

  /**
   * @param {Header} header
   * @param {?string} recaptchaToken
   * @param {?StripePaymentIntent} stripePaymentIntent
   * @param csData
   * @param userInformation
   * @param {?ChariotSuccessMetadata} chariotSuccessMetadata
   */
  postHeader(
    header,
    recaptchaToken,
    stripePaymentIntent,
    csData,
    userInformation,
    chariotSuccessMetadata
  ) {
    let headers = {};
    if (stripePaymentIntent !== null) {
      headers['stripe-payment-intent-id'] = stripePaymentIntent.id;
    } else if (chariotSuccessMetadata !== null) {
      headers['recaptcha-token'] = recaptchaToken;
      headers['chariot-workflow-session-id'] = chariotSuccessMetadata.workflowSessionId;
    } else {
      headers['recaptcha-token'] = recaptchaToken;
    }
    header.appState = {
      cart: this.cartMemory.retrieve(),
      csData: csData,
      userInformation: userInformation
    };
    return this.$http({
      url: '/api/giving_header/',
      method: 'POST',
      data: header,
      headers: headers,
    }).then(response => {
      let headerResponse = Object.assign(new Header(), response.data);
      return headerResponse;
    }).catch(error => {
      if (error.status === 403 || chariotSuccessMetadata !== null) {
        // recaptcha fail or unsuccessful DAF. For DAF we're not going
        // to write anything in the DB.
        this.$location.url(`/thank-you?reason_code=${error.status}`);
        return this.$q.reject(error);
      } else {
        // let it do what it always did
        return 'ERROR ON header call';
      }
    });
  }

  /**
   *
   * @param schoolCode
   * @param schools {DonationSchool[]}
   * @returns {?DonationSchool}
   */
  findFirstSchoolBySchoolCode(schoolCode, schools) {
    for (let school of schools) {
      if (school.school_code === schoolCode) {
        return school;
      }
    }
  }

  postDetail(detail) {
    return this.$http({
      url: '/api/giving_detail/',
      method: 'POST',
      data: detail,
    }).then(response => {
      let detailResponse = Object.assign(new Detail(), response.data);
      return detailResponse;
    }).catch(error => {
      console.error('error: ' + angular.toJson(error, true));
      return 'ERROR ON detail call';
    });
  }

  postSecurityOnlyOrDafGift(giftId) {
    return this.$http({
      url: '/api/giving_header/' + giftId + '/complete_donation/',
      method: 'POST',
      data: {status: 'S'}
    }).then(() => {
    }).catch(_error => {
    });
  }


  formatDate(d) {
    let
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


  /**
   * Will take a string or number and return it as a number - template wants a number
   */
  parseIntWithCommas(intWithCommas) {
    return this.cartMemory.parseIntWithCommas(intWithCommas);
  }

  getSignature(toBeSigned) {
    return this.$http.post(
      '/api/cyberSource/sign',
      toBeSigned
    ).then(response => {
      return response.data;
    }).catch(_error => {
      return 'ERROR ON SIGNATURE';
    });
  }

  stripeCompleteDonation(
    giftId,
    stripeToken,
    amount,
    anonymous
  ) {
    /** @type {GivingStripeCharge} */
    let charge = {
      gift_id: giftId,
      token_id: stripeToken,
      amount: amount,
      anonymous: anonymous,
      currency: this.currencySymbolSvc.get().code,
    };
    let reasonCode = null;
    return this.$http.post(
      '/api/stripe/charge/',
      charge,
    )
      .then(response => {
        /** @type {CompleteDonationResponse} */
        let chargeResponse = response.data;
        if (chargeResponse.status === 'S') {
          reasonCode = 100;
        } else {
          // anything but 100 is an error on the thank-you page, 101 & 102 are arbitrary
          // Use 101 & 102 to test the two types of error
          reasonCode = 101;
        }
        return this.$location.url(`/thank-you?reason_code=${reasonCode}`);
      })
      .catch(_error => {
        // anything but 100 is an error on the thank-you page, 101 & 102 are arbitrary
        // Use 101 & 102 to test the two types of error
        reasonCode = 102;
        return this.$location.url(`/thank-you?reason_code=${reasonCode}`);
      });
  }

  /**
   * @param {string} giftId
   * @param {StripePaymentMethod} stripePaymentMethod
   */
  stripeCompletePaymentIntentDonation(
    giftId,
    stripePaymentMethod,
  ) {
    let reasonCode = null;
    return this.givingService.stripeCompletePaymentIntentDonation(giftId, stripePaymentMethod)
      .then(response => {
        /** @type {CompleteDonationResponse} */
        let chargeResponse = response;
        if (chargeResponse.status === 'S') {
          reasonCode = 100;
        } else {
          // anything but 100 is an error on the thank-you page, 101 & 102 are arbitrary
          // Use 101 & 102 to test the two types of error
          reasonCode = 101;
        }
        return this.$location.url(`/thank-you?reason_code=${reasonCode}`);
      })
      .catch(_error => {
        // anything but 100 is an error on the thank-you page, 101 & 102 are arbitrary
        // Use 101 & 102 to test the two types of error
        reasonCode = 102;
        return this.$location.url(`/thank-you?reason_code=${reasonCode}`);
      });
  }

  /**
   * @param csData data for cybersource
   * @param cartMemory
   * @param userInformation
   * @param {?StripeToken} stripeToken a stripe token
   * @param {?string} recaptchaToken the recaptcha token
   * @param {?StripePaymentMethod} stripePaymentMethod stripe payment method for digital wallet payments
   * @param {?StripePaymentIntent} stripePaymentIntent stripe payment method for digital wallet payments
   * @param {?ChariotSuccessMetadata} chariotSuccessMetadata response
   */
  checkout(
    csData,
    cartMemory,
    userInformation,
    stripeToken,
    recaptchaToken,
    stripePaymentMethod,
    stripePaymentIntent,
    chariotSuccessMetadata
  ) {
    if (chariotSuccessMetadata !== null) {
      // in chariot they can change the amount
      this.cartMemory.cartContents[0].amount =
        (new Decimal(chariotSuccessMetadata.grantIntent.amount)).dividedBy(100).toNumber();
    }
    let usingStripe = !!stripeToken;
    return this.storeGiftCart(
      userInformation,
      csData,
      usingStripe,
      recaptchaToken,
      stripePaymentIntent,
      chariotSuccessMetadata,
    )
      .then(giftRecorderResult => {
        let giftId = giftRecorderResult[0].gift_id;

        if (stripeToken) {
          return this.stripeCompleteDonation(
            giftId,
            stripeToken.id,
            cartMemory.getTotals().cyberSourceAmount,
            this.getAnonymous(cartMemory),
          );
        }

        if (stripePaymentMethod) {
          return this.stripeCompletePaymentIntentDonation(
            giftId,
            stripePaymentMethod,
          );
        }

        csData.merchant_defined_data4 = giftId;

        if (cartMemory.getTotals().cyberSourceAmount === 0 || cartMemory.getTotals().donorAdvisedFund !== null) {
          // Security-only or DAF gift
          return this.postSecurityOnlyOrDafGift(giftId)
            .then(() => {
              return this.$q.when(this.$location.url('/thank-you'));
            });
        } else {
          csData.signature = '';  //make sure this is zero'd out - will pick
          // up problems sooner - for instance if values are left over

          // one-time amount can be changed on this page, so grab amount at last moment
          csData.amount = cartMemory.getTotals().cyberSourceAmount;
          csData.reference_number = parseInt(giftRecorderResult[0].order_number); // just make this an int since that's what it used to be before GIVING-435
          csData.transaction_uuid = giftRecorderResult[0].transaction_uuid;

          let oneTimeOrRecurringGiftPromise = this.oneTimeOrRecurringGift(csData, cartMemory);

          if (this.utilService.isStripeCart(this.cartMemory.cartContents)) {
            // use GIVING-410's new stripe logic - even if stripe is not used as in UK gifts (GIVING-443)
            csData.currency = this.currencySymbolSvc.get().code;
          } else {
            // it was already set on on the personal information form
          }

          if (csData.bill_to_address_country !== 'US'
            && csData.bill_to_address_country !== 'CA'
            && csData.bill_to_address_country !== 'CN'
          ) {
            // GIVING-537
            csData.bill_to_address_state = ''
          }

          return this.$q.all([oneTimeOrRecurringGiftPromise])
            .then(() => {
              csData.signed_date_time = new Date().toISOString().split('.')[0] + "Z";
              return this.getSignature(csData);
            })
            .then((signature) => {
              csData.signature = signature;

              // guarantee html form is updated with signature - will call this.$scope.$apply()
              // after currently-running digest is in progress
              // this.$scope.$apply() can cause a conflict with currently running digest
              return this.$timeout();
            })
            .then(() => {
              let cyberSourceUrl = this.dataLoader.cyberSourceConfig.orderFormUrl;
              // noinspection JSCheckFunctionSignatures
              let form = angular.element(document).find('form');
              form[0].setAttribute('action', cyberSourceUrl);
              form[0].setAttribute('method', 'post');
              // form[0].setAttribute('action', '/api/cyberSourceTest');
              form[0].submit();
            });
        }
      })
      .finally(() => {
        // set flag to allow thank-you page to be seen GIVING-369
        this.$sessionStorage.donationSubmitted = true;
      });
  }

  oneTimeOrRecurringGift(csData, cartMemory) {
    let gettingEmplid = this.$q.when(); // nothing to return
    let cartMemoryStatus = cartMemory.status();
    if (cartMemoryStatus.oneTime && cartMemoryStatus.recurring) {
      // This should have been prevented by the UI
      throw new Error("can't have both a recurring and one time in cart");
    }

    if (cartMemoryStatus.oneTime || cartMemory.containsRecurringPledgeWithOneTimePayment()) {
      csData.transaction_type = "sale";
      csData.unsigned_field_names = csData.unsigned_field_names.concat(",merchant_defined_data3");
      csData.merchant_defined_data3 = this.getAnonymous(cartMemory);
      // Amount will be calculated on submit because they can change on this page
    } else if (cartMemoryStatus.recurring || cartMemory.containsRecurringPledgeWithAutoCharge()) {
      if (cartMemory.retrieve().length > 1) {
        throw new Error("can't have more than one fund if you have a recurring");
      }
      let cartItem = cartMemory.retrieve()[0];
      if (cartMemory.getTotals().yearly) {
        if (cartMemory.getTotals().monthly) {
          throw new Error("can't have both yearly and monthly");
        }
        if (cartMemory.getTotals().weekly) {
          throw new Error("can't have both yearly and weekly");
        }
        if (cartMemory.getTotals().quarterly) {
          throw new Error("can't have both yearly and quarterly");
        }
        csData.recurring_amount = cartMemory.getTotals().yearly;
        csData.recurring_frequency = "annually";
      } else if (cartMemory.getTotals().monthly) {
        if (cartMemory.getTotals().yearly) {
          throw new Error("can't have both monthly and yearly");
        }
        if (cartMemory.getTotals().weekly) {
          throw new Error("can't have both monthly and weekly");
        }
        if (cartMemory.getTotals().quarterly) {
          throw new Error("can't have both monthly and quarterly");
        }
        csData.recurring_amount = cartMemory.getTotals().monthly;
        csData.recurring_frequency = "monthly";
      } else if (cartMemory.getTotals().weekly) {
        if (cartMemory.getTotals().yearly) {
          throw new Error("can't have both weekly and yearly");
        }
        if (cartMemory.getTotals().monthly) {
          throw new Error("can't have both weekly and monthly");
        }
        if (cartMemory.getTotals().quarterly) {
          throw new Error("can't have both weekly and quarterly");
        }
        csData.recurring_amount = cartMemory.getTotals().weekly;
        csData.recurring_frequency = "weekly";
      } else if (cartMemory.getTotals().quarterly) {
        if (cartMemory.getTotals().yearly) {
          throw new Error("can't have both quarterly and yearly");
        }
        if (cartMemory.getTotals().monthly) {
          throw new Error("can't have both quarterly and monthly");
        }
        if (cartMemory.getTotals().weekly) {
          throw new Error("can't have both quarterly and weekly");
        }
        csData.recurring_amount = cartMemory.getTotals().quarterly;
        csData.recurring_frequency = "quarterly";
      }

      csData.merchant_defined_data1 = '';//emplid#fund code#appeal code'
      gettingEmplid = this.$http.get('/api/giving_donor_pers_info/justemplid/')
        .then((response) => {
          let emplid = response.data;
          return emplid;
        })
        .catch(_emplid => {
            return '1579195';
          }
        )
        .then(emplid => {

          /** @type {DonationFund} */
          let fund = this.dataLoader.funds.find(fund => {
            return fund.hibernate_id === cartItem.id;
          });

          csData.merchant_defined_data1 += emplid;
          csData.merchant_defined_data1 += '#' + fund.fund_code + '#';
          if (cartItem.appeal) {
            csData.merchant_defined_data1 += cartItem.appeal;
          }
        });

      csData.merchant_defined_data2 = 'RecurrGift';

      csData.transaction_type = "authorization,create_payment_token";
      csData.recurring_start_date = this.formatDateForCs(new Date(cartItem.startDate));
      csData.recurring_number_of_installments = '';
      if (cartItem.numberOfPayments) {
        csData.recurring_number_of_installments = cartItem.numberOfPayments;
      }

      csData.recurring_automatic_renew = 'false'

      csData.unsigned_field_names =
        csData.unsigned_field_names.concat(
          ",recurring_start_date,recurring_frequency,recurring_amount,recurring_number_of_installments"
          + ",recurring_automatic_renew"
          + ",merchant_defined_data1,merchant_defined_data2");

    }
    return gettingEmplid;

  }


  formatDateForCs(date) {
    let mm = date.getMonth() + 1; // getMonth() is zero-based
    let dd = date.getDate();

    return [date.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('');
  }

  getAnonymous(cartMemory) {
    let anon = 'not anonymous';
    if (cartMemory.containsAnonymous()) {
      anon = 'anonymous';
    }
    return anon;
  }

}

export default angular.module('givingApp.checkout', [])
  .service('checkout', CheckoutService)
  .name;
